<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row wrap text-xs-center>
          <v-flex xs12 mb-4 class="person_name blue--text text--lighten-1"
            >{{ userData.first_name }} {{ userData.last_name }}</v-flex
          >
          <v-flex xs12 mb-5 v-if="depositories && depositories.length > 0">
            <v-layout row wrap>
              <div
                class="left"
                v-for="(depository, depositoryIndex) in depositories"
                :key="`depository-${depositoryIndex}`"
              >
                <v-btn
                  v-if="depository.available_slots > 0"
                  @click="createDeposit(depository.id)"
                  class="deposit_action_button"
                  flat
                >
                  <DepositType
                    :value="assignDepositType(depository.deposit_type_id)"
                    :groupId="groupId"
                  />
                </v-btn>
              </div>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            mb-5
            v-if="depositsLoaded && deposits && deposits.length > 0"
          >
            <div v-if="!showPreloader">
              <v-layout
                row
                wrap
                v-for="(deposit, depositIndex) in deposits"
                :key="`deposit-${depositIndex}`"
              >
                <v-flex xs2></v-flex>
                <v-flex xs8>
                  <label
                    :class="[
                      'deposit-item',
                      { 'new-item': deposit.new },
                      selectedDeposits.includes(deposit.id) ? 'out' : '',
                    ]"
                  >
                    <!-- <label :class="['deposit-item']"> -->
                    <input
                      type="checkbox"
                      v-model="selectedDeposits"
                      :value="deposit.id"
                    />
                    <div v-if="deposit && deposit.deposit_type">
                      <div class="deposit_type">
                        <font-awesome-icon
                          :icon="transformIcon(deposit.deposit_type.icon)"
                          :color="deposit.deposit_type.color"
                        />
                        <span
                          :style="`color: ${deposit.deposit_type.color}`"
                          class="ml-4 mr-2"
                          >{{ deposit.deposit_type.name }}</span
                        >
                      </div>
                      <div class="deposit_number">{{ deposit.slug }}</div>
                    </div>
                  </label>
                </v-flex>
                <v-flex xs2></v-flex>
                <!-- <v-flex xs2>
              <v-btn icon @click="updateDeposit(deposit.id)" class="delete_button">
                <font-awesome-icon icon="trash" />
              </v-btn>
                </v-flex>-->
              </v-layout>
            </div>
          </v-flex>
          <v-flex v-if="showPreloader" xs12 px-5>
            <v-progress-linear
              v-model="buffer"
              :buffer-value="100"
              buffer
              class="theme-accent"
            ></v-progress-linear>
          </v-flex>
          <v-flex v-if="!showPreloader" xs12>
            <v-btn class="ml-0 blue-button big_blue_button" @click="confirm">{{
              $t("confirm")
            }}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import DepositType from "@/components/Deposits/DepositType.vue";

export default {
  name: "Deposits",
  props: ["userData"],
  components: {
    DepositType,
  },
  data: () => ({
    depositsLoaded: false,
    selectedDeposits: [],
    showPreloader: false,
    perPage: 100,
    buffer: 0,
  }),
  computed: {
    ...mapGetters(["getDeposits", "getDepositories", "getDepositTypes"]),
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    deposits() {
      return this.getDeposits;
    },
    depositories() {
      return this.getDepositories;
    },
    depositTypes() {
      return this.getDepositTypes;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.listDeposits();
        this.listDepositories();
        this.listDepositTypes();
      },
    },
  },
  methods: {
    transformIcon(icon) {
      if (!icon) return [];
      return icon.split(" ");
    },
    assignDepositType(id) {
      const index = this.depositTypes.findIndex(
        (depositType) => depositType.id == id,
      );
      if (index > -1) {
        return this.depositTypes[index];
      }
    },
    listDeposits() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
          customer_id: this.userData.id,
        },
      };
      this.$store.dispatch("listDeposits", params).then((data) => {
        if (data) {
          this.depositsLoaded = true;
        }
      });
    },
    listDepositTypes() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
        },
      };
      this.$store.dispatch("listDepositTypes", params);
    },
    listDepositories() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
          available_slots: 1,
          per_page: this.perPage,
        },
      };
      this.$store.dispatch("listDepositories", params);
    },
    createDeposit(depository_id) {
      const params = {
        group_id: this.groupId,
        model: {
          group_plugin_id: this.groupPluginId,
          depository_id: depository_id,
          customer_id: this.userData.id,
        },
      };
      this.$store.dispatch("createDeposits", params).then((data) => {
        if (data) {
          const logParams = {
            user: `${this.userData.first_name} ${this.userData.last_name}`,
            deposit_type: data.data.deposit_type
              ? data.data.deposit_type.name
              : "",
            number: data.data.name,
            action: "delivered",
            time_stamp: moment().tz(this.userTimezone),
          };
          this.$store.dispatch("addDepositHistoryLog", logParams);
          this.listDepositories();
        }
      });
    },
    updateDeposit(id) {
      if (id) {
        const params = {
          group_id: this.groupId,
          deposit_id: id,
          model: {
            disabled: 1,
          },
        };
        return this.$store.dispatch("updateDeposits", params);
      }
    },
    // deleteDeposits() {
    //   if (this.selectedDeposits && this.selectedDeposits.length) {
    //     (async () => {
    //       for (let depositId of this.selectedDeposits) {
    //         let index = this.deposits.findIndex(
    //           deposit => deposit.id == depositId
    //         );
    //         if (index > -1) {
    //           const params = {
    //             group_id: this.groupId,
    //             deposit_id: depositId
    //           };
    //           await this.$store.dispatch("deleteDeposits", params);
    //         }
    //       }
    //     })().then((response) => {
    //       this.listDepositories();
    //     });
    //   }
    //   this.$emit("close-inventory");
    // },
    async confirm() {
      if (this.selectedDeposits.length > 0) {
        try {
          this.showPreloader = true;
          this.buffer = 0;
          for (let depositId of this.selectedDeposits) {
            const progress = 100 / this.selectedDeposits.length;
            this.buffer = this.buffer + progress;

            let response = await this.updateDeposit(depositId);
            if (response) {
              const logParams = {
                user: `${this.userData.first_name} ${this.userData.last_name}`,
                deposit_type: response.data.deposit_type
                  ? response.data.deposit_type.name
                  : "",
                number: response.data.name,
                action: "retrieved",
                time_stamp: moment().tz(this.userTimezone),
              };
              this.$store.dispatch("addDepositHistoryLog", logParams);
            }
          }
        } catch (e) {
        } finally {
          this.showPreloader = false;
          this.$emit("close-inventory");
        }
      } else {
        this.$emit("close-inventory");
      }
    },
  },
};
</script>

<style scoped>
.person_name {
  font-size: 32px;
}
.deposit_action_button {
  font-size: 16px;
  height: auto;
  padding: 15px 25px;
  margin: 10px 20px 20px 20px;
  background: #fff;
  border: 1px solid #01adef;
}

.deposit-item {
  min-width: 300px;
  width: 100%;
  display: inline-block;
  padding: 15px 20px;
  border: 1px solid #d4d4d4;
  /* cursor: pointer; */
  font-size: 24px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 5px;
}

.deposit-item .deposit_type {
  float: left;
  margin: 12px 10px;
}
.deposit-item .deposit_number {
  float: right;
  margin: 0 10px;
  font-weight: bold;
  font-size: 40px;
}

.deposit-item input {
  display: none;
}

.deposit-item.new-item {
  color: green;
  border-color: green;
}

.deposit-item.out {
  color: red;
  border-color: red;
}

.delete_button {
  font-size: 28px;
  margin-top: 25px;
  opacity: 0.1;
}

.blue-button.big_blue_button {
  height: 80px !important;
  margin-right: 0 !important;
  min-width: 220px;
  font-size: 30px;
  margin-bottom: 60px !important;
}

.user_data .v-dialog .v-card {
  background: red;
  color: red;
}
</style>
