<template>
  <v-card class="pa-4" flat>
    <v-toolbar class="pb-1" color="white" flat>
      <v-toolbar-title
        style="text-transform:uppercasefont-size:16px"
        class="pa-0 ma-0"
      >
        <strong>History</strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeHistoryLogDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text flat>
      <v-layout row wrap>
        <v-flex xs12 v-if="!hideLoader">
          <v-progress-linear
            :indeterminate="true"
            class="theme-accent"
          ></v-progress-linear>
        </v-flex>
        <v-flex xs12 v-if="hideLoader">
          <perfect-scrollbar :options="scrollbarOptions" style="height:600px">
            <v-list two-line subheader>
              <v-list-tile
                v-for="(log, logIndex) in logs"
                :key="`log-${logIndex}`"
              >
                <v-list-tile-content>
                  <v-list-tile-sub-title>{{
                    transformDate(log.created_at)
                  }}</v-list-tile-sub-title>
                  <div>{{ log.description }}</div>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </perfect-scrollbar>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "DepositHistory",
  props: ["customerId"],
  components: {
    PerfectScrollbar,
  },
  data: () => ({
    logs: {},
    scrollbarOptions: {
      wheelSpeed: 2,
      suppressScrollX: true,
      wheelPropagation: true,
    },
    hideLoader: false,
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
  },
  created() {
    this.depositsHistory();
  },
  methods: {
    closeHistoryLogDialog() {
      this.$emit("close-history-log-dialog");
    },
    transformDate(date) {
      return moment.tz(date, this.userTimezone).format("YYYY-MM-DD HH:mm");
    },
    depositsHistory() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
          causer_id: this.currentUser.id,
          customer_id: this.customerId,
          sort: "id:desc",
        },
      };
      this.$store.dispatch("depositsHistory", params).then((data) => {
        this.hideLoader = true;
        if (data) {
          this.logs = data.data;
        }
      });
    },
  },
};
</script>
