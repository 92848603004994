<template>
  <v-card>
    <v-toolbar class="pb-1 pt-2" color="white" flat>
      <v-toolbar-title
        style="text-transform:uppercasefont-size:16px"
        class="pa-0 ma-0"
      >
        <strong>{{ $t("ranges") }}</strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDepositoriesDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-layout row wrap>
        <v-flex xs12 v-if="showLoader">
          <v-progress-linear
            :indeterminate="true"
            class="theme-accent"
          ></v-progress-linear>
        </v-flex>
        <v-flex xs12 pb-2>
          <v-divider></v-divider>
        </v-flex>
        <template v-if="!showLoader">
          <v-flex
            xs12
            v-for="(depository, depositoryIndex) in depositories"
            :key="'depository-' + depositoryIndex"
          >
            <v-layout row wrap class="align-items-center">
              <v-flex xs4 pl-4>
                <DepositType
                  :value="assignDepositType(depository.deposit_type_id)"
                  :groupId="groupId"
                />
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  v-model="depository.min"
                  v-validate.disable="'required'"
                  :error-messages="errors.collect('min')"
                  data-vv-name="min"
                  :data-vv-as="$tc('min', 2)"
                  :label="$tc('min', 1)"
                  hide-details
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pl-2>
                <v-text-field
                  v-model="depository.max"
                  v-validate.disable="'required'"
                  :error-messages="errors.collect('max')"
                  data-vv-name="max"
                  :data-vv-as="$tc('max', 2)"
                  :label="$tc('max', 1)"
                  hide-details
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs3 pr-3 pl-5>
                <v-btn
                  class="blue-button"
                  @click="updateDepository(depository)"
                  >{{ $t("save") }}</v-btn
                >
              </v-flex>
              <v-flex xs1 text-xs-right pr-3>
                <v-btn
                  flat
                  icon
                  color="grey lighten-1"
                  class="hover_opacity"
                  @click="deleteDepository(depository.id)"
                >
                  <font-awesome-icon class="important--text" icon="trash" />
                </v-btn>
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4 mb-3>
            <v-btn class="ml-0 simple-blueborder-button" @click="showDialog">
              {{ $t("add") }}
            </v-btn>
          </v-flex>
        </template>
        <v-flex xs12>
          <v-dialog v-model="dialog" width="800">
            <v-card>
              <v-toolbar class="pb-1 pt-2" color="white" flat>
                <v-toolbar-title
                  style="text-transform:uppercasefont-size:16px"
                  class="pa-0 ma-0"
                >
                  <strong>{{ $t("new-range") }}</strong>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-layout row wrap pa-4>
                <v-flex xs12>
                  <v-text-field
                    v-model="model.name"
                    :error-messages="errors.collect('name')"
                    v-validate.disable="'required'"
                    data-vv-name="name"
                    :data-vv-as="$tc('name', 2)"
                    :label="$tc('name', 1)"
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="model.deposit_type_id"
                    :items="depositTypes"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.collect('depositType')"
                    v-validate.disable="'required'"
                    data-vv-name="depositType"
                    :data-vv-as="$tc('deposit-type', 2)"
                    :label="$tc('deposit-type', 1)"
                    box
                  >
                    <template
                      v-if="data.item"
                      slot="selection"
                      slot-scope="data"
                    >
                      <font-awesome-icon
                        v-if="data.item.icon"
                        :icon="transformIcon(data.item.icon)"
                        :color="data.item.color"
                      />
                      <span class="body-2 pl-3">{{ data.item.name }}</span>
                    </template>
                    <template v-if="data.item" slot="item" slot-scope="data">
                      <font-awesome-icon
                        v-if="data.item.icon"
                        :icon="transformIcon(data.item.icon)"
                        :color="data.item.color"
                      />
                      <span class="body-2 pl-3">{{ data.item.name }}</span>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs6 pr-2>
                  <v-text-field
                    v-model="model.min"
                    :error-messages="errors.collect('min')"
                    v-validate.disable="'required'"
                    data-vv-name="min"
                    :data-vv-as="$tc('min', 2)"
                    :label="$tc('min', 1)"
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 pl-2>
                  <v-text-field
                    v-model="model.max"
                    :error-messages="errors.collect('max')"
                    v-validate.disable="'required'"
                    data-vv-name="max"
                    :data-vv-as="$tc('max', 2)"
                    :label="$tc('max', 1)"
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    large
                    color="blue lighten-1"
                    dark
                    class="ml-0"
                    @click="createDepositories"
                    >{{ $t("create") }}</v-btn
                  >
                  <v-btn large class="ml-0" @click="dialog = false">
                    {{ $t("cancel") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DepositType from "@/components/Deposits/DepositType.vue";

export default {
  name: "Depositories",
  components: {
    DepositType,
  },
  data: () => ({
    showLoader: true,
    model: {},
    dialog: false,
  }),
  computed: {
    ...mapGetters(["getDepositories", "getDepositTypes"]),
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    depositories() {
      return this.getDepositories;
    },
    depositTypes() {
      return this.getDepositTypes;
    },
  },
  created() {
    this.listDepositories();
    this.listDepositTypes();
  },
  methods: {
    closeDepositoriesDialog() {
      this.$emit("close-depositories-dialog");
    },
    showDialog() {
      this.listDepositTypes();
      this.dialog = true;
    },
    transformIcon(icon) {
      if (!icon) return;
      return icon.split(" ");
    },
    assignDepositType(id) {
      const index = this.depositTypes.findIndex(
        (depositType) => depositType.id == id,
      );
      if (index > -1) {
        return this.depositTypes[index];
      }
    },
    listDepositTypes() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
        },
      };
      this.$store.dispatch("listDepositTypes", params);
    },
    createDepositories() {
      this.$validator.validate().then((result) => {
        if (result) {
          const params = {
            group_id: this.groupId,
            model: {
              group_plugin_id: this.groupPluginId,
              ...this.model,
            },
          };
          this.$store.dispatch("createDepositories", params).then((data) => {
            if (data) {
              this.$store.dispatch("addNotification", {
                message: `Range created`,
              });
              this.dialog = false;
              this.model = {};
            }
          });
        }
      });
    },
    listDepositories() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
        },
      };
      this.$store.dispatch("listDepositories", params).then((data) => {
        if (data) {
          this.showLoader = false;
        }
      });
    },
    updateDepository(model) {
      if (model) {
        const params = {
          group_id: this.groupId,
          depository_id: model.id,
          model: {
            min: model.min,
            max: model.max,
          },
        };
        this.$store.dispatch("updateDepositories", params).then((data) => {
          if (data) {
            this.$store.dispatch("addNotification", {
              message: `Range updated`,
            });
          }
        });
      }
    },
    deleteDepository(id) {
      if (id) {
        this.$vuetifyConfirmDialog
          .open(
            "Confirmation",
            "Are you sure you want delete this range? This can't be undone",
            "Cancel",
            "Confirm",
          )
          .then((state) => {
            if (state) {
              const params = {
                group_id: this.groupId,
                depository_id: id,
                params: {
                  group_plugin_id: this.groupPluginId,
                },
              };
              this.$store
                .dispatch("deleteDepositories", params)
                .then((data) => {
                  this.$Progress.finish();
                  if (data) {
                    this.$store.dispatch("addNotification", {
                      message: `Range deleted`,
                    });
                  }
                });
            }
          });
      }
    },
  },
};
</script>
