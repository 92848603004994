<template>
  <v-container class="general">
    <v-layout row wrap>
      <v-flex :class="depositsHistoryLog.length > 0 ? 'xs9 pr-1' : 'xs12'">
        <v-layout row wrap>
          <v-flex xs12 text-xs-center v-if="!rfidData.id">
            <v-layout row wrap>
              <v-flex xs12>
                <a href="#" @click.prevent="historyLogDialog = true">
                  {{
                  $t("history")
                  }}
                </a>
              </v-flex>
              <v-flex xs12 my-5 text-xs-center blue--text text--lighten-1 class="display-1">
                {{
                processing ? $t("processing") : $t("awaiting-scan")
                }}
              </v-flex>
              <v-flex
                xs12
                v-for="(depository, depositoryIndex) in depositories"
                :key="'deposistory-' + depositoryIndex"
              >
                <DepositType
                  :value="assignDepositType(depository.deposit_type_id)"
                  :groupId="groupId"
                  class="display-inline-block mr-2"
                ></DepositType>
                <span class="mr-2">{{ depository.available_slots }}</span>
                {{ $t("slots-available") }}
              </v-flex>
              <v-flex xs12 mt-5>
                <a href="#" @click.prevent="depositoriesDialog = true">
                  {{
                  $t("depository-settings")
                  }}
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            py-4
            v-if="errorMessage"
            red--text
            text--darken-2
            class="display-1"
            text-xs-center
            v-html="errorMessage"
          ></v-flex>
          <v-flex xs12 v-if="rfidData && rfidData.id">
            <Deposits :userData="rfidData" @close-inventory="closeInventory"></Deposits>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs3 v-if="depositsHistoryLog.length > 0">
        <v-layout row wrap>
          <v-flex xs12 mb-5>&nbsp;</v-flex>
          <v-flex xs12 class="history-area pa-2">
            <perfect-scrollbar :options="scrollbarOptions" style="height:600px">
              <div class="mb-2" v-for="(log, index) in depositsHistoryLog" :key="'log-' + index">
                <div>
                  <strong>{{ log.user }}</strong>
                </div>
                <div>
                  {{ log.deposit_type }} - {{ log.number }} /
                  <span
                    :class="
                      log.action == 'delivered' ? 'green--text' : 'red--text'
                    "
                  >{{ log.action }}</span>
                </div>
                <div>{{ transformTime(log.time_stamp) }} ago</div>
              </div>
            </perfect-scrollbar>
          </v-flex>
          <!-- <v-flex v-if="depositsHistoryLog.length > 0" xs12 mb-5 mt-4>
          <v-btn class="regular-grayborder-button" @click="clearHistoryLog">CLEAR</v-btn>
          </v-flex>-->
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="historyLogDialog"
      @keydown.esc="historyLogDialog = false"
      scrollable
      width="800"
    >
      <DepositHistory v-if="historyLogDialog" @close-history-log-dialog="closeHistoryLogDialog"></DepositHistory>
    </v-dialog>
    <v-dialog v-model="depositoriesDialog" @keydown.esc="depositoriesDialog = false" width="800">
      <Depositories v-if="depositoriesDialog" @close-depositories-dialog="closeDepositoriesDialog"></Depositories>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import "moment-duration-format";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Deposits from "@/components/Deposits/Deposits.vue";
import Depositories from "@/components/Depositories/Depositories.vue";
import DepositHistory from "@/components/Deposits/DepositHistory.vue";
import DepositType from "@/components/Deposits/DepositType.vue";

export default {
  components: {
    Deposits,
    Depositories,
    DepositHistory,
    DepositType,
    PerfectScrollbar,
  },
  data: () => ({
    scrollbarOptions: {
      wheelSpeed: 2,
      suppressScrollX: true,
      wheelPropagation: true,
    },
    depositoriesDialog: false,
    historyLogDialog: false,
    scannerInput: [],
    tokenType: "rfid",
    rfidData: {},
    errorMessage: null,
    processing: false,
    timeout: null,
  }),
  computed: {
    ...mapGetters([
      "getDepositories",
      "getDepositTypes",
      "getDepositsHistoryLog",
    ]),
    groupId() {
      return this.$route.params.group_id;
    },
    depositories() {
      return this.getDepositories;
    },
    depositTypes() {
      return this.getDepositTypes;
    },
    inputValue() {
      return this.scannerInput.join("").replace(/\s/g, "");
    },
    depositsHistoryLog() {
      return this.getDepositsHistoryLog;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.listDepositories();
        this.listDepositTypes();
      },
    },
    depositories: {
      immediate: true,
      handler(val) {
        let availableSlots = 0;
        val.forEach((v) => {
          availableSlots = availableSlots + v.available_slots;
        });
        if (availableSlots === 0) {
          this.depositoriesDialog = true;
        }
      },
    },
    inputValue(val) {
      if (val) {
        this.validateInput(val);
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      const charList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
      const key = event.key.toUpperCase();
      if (charList.indexOf(key) === -1) return;
      this.scannerInput.push(key);
    });
  },
  methods: {
    clearHistoryLog() {
      this.$store.dispatch("clearDepositsHistoryLog");
    },
    transformTime(date) {
      let now = moment().tz(this.userTimezone);
      let before = moment(date).tz(this.userTimezone);
      let diff = now.diff(before, "seconds");
      if (diff <= 60) {
        return moment.duration(diff, "seconds").format("s [second]");
      }
      return moment.duration(diff, "seconds").humanize();
    },
    validateInput(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.scannerInput = [];
      }, 500);

      const tokenRegExp = new RegExp("^([A-F0-9 ]{2}){7}$");

      if (tokenRegExp.test(val)) {
        if (!this.rfidData.id) {
          this.processing = true;
          this.getUserData(val);
        }
      }
    },
    closeDepositoriesDialog() {
      this.depositoriesDialog = false;
    },
    closeHistoryLogDialog() {
      this.historyLogDialog = false;
    },
    closeInventory() {
      this.rfidData = {};
    },
    assignDepositType(id) {
      const index = this.depositTypes.findIndex(
        (depositType) => depositType.id == id,
      );
      if (index > -1) {
        return this.depositTypes[index];
      }
    },
    getUserData(token) {
      if (!token) {
        this.processing = false;
        return;
      }

      const params = [this.groupId, this.tokenType, token];

      this.$api.groupUserPhysicalTokens
        .get(...params)
        .then((response) => {
          this.processing = false;

          if (response && response.data.status == "ok") {
            this.errorMessage = "";
            this.rfidData = data.data;
          } else {
            this.errorMessage = this.$t("chip-has-not-been-checked-in");
          }
        })
        .catch(() => {
          this.processing = false;
        });
    },
    listDepositories() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
          available_slots: 1,
        },
      };
      this.$store.dispatch("listDepositories", params);
    },
    listDepositTypes() {
      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
        },
      };
      this.$store.dispatch("listDepositTypes", params);
    },
  },
};
</script>

<style>
.display-inline-block {
  display: inline-block;
}

.big_field.v-input input {
  max-height: 80px;
  height: 80px;
  font-size: 30px;
}

.clean_ticket_button {
  font-size: 32px;
}

.history-area {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
